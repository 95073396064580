@import "./../../style/color.scss";
@import "./../../style/breakpoint.scss";

nav {
  height: 15vh;
  width: 90%;
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: radial-gradient($red-rgb, $black-rgba-end);
  .nav-item-container {
    display: flex;
  }
}

.nav-item {
  display: grid;
  place-content: center;
  margin: 0 20px;
  height: 100%;
  transition: all 0.5 ease-in;
  #nav-logo {
    width: 75px;
    height: 75px;
  }
  a {
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    .nav-border-bottom {
      width: 0;
      visibility: hidden;
      border: 1px solid $green-hex;
      transition: all 0.5s ease-in;
    }
  }

  a:hover .nav-border-bottom,
  a:active .nav-border-bottom,
  a:focus .nav-border-bottom {
    visibility: visible;
    width: 100%;
  }
}
.hamburger {
  font-size: 2rem;
  display: none;
  visibility: none;
}

.nav-drawer {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  background-image: radial-gradient($red-rgb, $black-rgba-end);
  width: 0;
  visibility: visible;
  z-index: 1500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.3s ease-out;
  .nav-close-button {
    font-size: 2rem;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .nav-item {
    height: auto;
    padding: 10px;
    white-space: nowrap;
  }
}

.nav-drawer.active {
  width: 100%;
  visibility: visible;
}

@media only screen and (max-width: $tablet) {
  .hamburger {
    display: block;
    visibility: visible;
  }

  nav {
    .nav-item-container {
      display: none;
      visibility: none;
    }
  }
}
