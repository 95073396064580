@import "./../style/color.scss";
@import "./../style/typography.scss";
@import "./../style/breakpoint.scss";

#business-info {
  width: 100%;
  height: auto;
  margin: 20px 0;
  padding: 100px 0;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  p {
    font-size: $one + 0.2;
  }
  span.special-text {
    font-weight: 900;
    color: $green-hex;
  }
  .telco,
  .social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    span {
      margin-right: 10px;
    }
  }
  .social {
    .light {
      font-size: $one * 2;
      margin: 5px;
    }
  }
  .social:hover {
    opacity: 0.8;
  }

  .gallery {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-flow: wrap;
    justify-content: center;
    img {
      width: 350px;
      margin: 10px;
      border-radius: 10px;
    }
    img:hover {
      opacity: 0.8;
    }
  }
  #gallery {
    align-self: flex-start;
    width: 100%;
    text-align: left;
  }
  p a {
    font-weight: 500;
    color: $green-hex;
  }

  ul {
    margin: 10px;
    li {
      margin: 5px;
      list-style: none;
      a {
        font-weight: 500;
        font-size: $one + 0.5;
        color: $green-hex;
      }
    }
  }
}

.gallery-1 {
  display: flex;
  flex-wrap: wrap;
  flex-flow: wrap;
  justify-content: center;
  height: auto;
  width: 100%;
  padding: 15px 0;
  margin-top: 20px;
  img {
    width: 30%;
    height: auto;
    margin: 15px 15px;
    border-radius: 15px;
  }
}

#starter-button{
  width: 30%;
  padding: 15px;
  background-color: $red-hex;
  border-radius: 10px;
  border: none;
  align-self: center;
  justify-self: flex-end;
  font-size: $one;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 30px;
}

#starter-button:hover{
  opacity: 0.9;
}

@media only screen and (max-width: $mobile) {
  .gallery-1{
    img{
      width: 80%;
    }
  }
}
