@import "./../../style/color.scss";
@import "./../../style/breakpoint.scss";
@import "./../../style/typography.scss";

.card-container {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  .card-item {
    margin: 20px;
    background-color: $black-rgba-middle;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    width: auto;
    flex-direction: column;
    align-items: center;
    position: relative;


   

    img {
      width: 240px;
      height: 240px;
    }
    .card-details {
      margin: 10px 0;
      width: 100%;

      .price {
        font-weight: 100;
        text-transform: capitalize;
      }
    }

    button {
      margin-top: auto;
      height: 40px;
      width: 100px;
      background-color: $green-hex;
      border-radius: 5px;
      font-weight: 400;
      border: none;
      font-size: $one;
      letter-spacing: 1px;
      outline-color: $white-rgba-start;
      transition: all 0.5s ease-in-out;
    }
    button:hover,
    button:focus {
      opacity: 0.8;
    }
  }

  .card-item-pasta {
    margin: 20px;
    background-color: $black-rgba-middle;
    border-radius: 10px;
    display: flex;
    width: 250px;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: relative;
    .card-img {
      width: 100%;
      height: 240px;
      background-position: center;
      background-size: cover;
      border-radius: 10px;
    }
    .card-details {
      margin: 10px 0;
      width: 100%;
      .price {
        font-weight: 100;
        text-transform: capitalize;
      }
    }

    button {
      margin-top: auto;
      height: 40px;
      width: 100px;
      background-color: $green-hex;
      border-radius: 5px;
      font-weight: 400;
      border: none;
      font-size: $one;
      letter-spacing: 1px;
      outline-color: $white-rgba-start;
      transition: all 0.5s ease-in-out;
    }
    button:hover,
    button:focus {
      opacity: 0.8;
    }
  }
}

.tag{
  padding: 5px 10px;
  border-radius: 50px;
  background-color: rgba(34, 189, 65,0.4);
  border:  solid 2px $green-rgb;
  font-size: $one - 0.3;
  font-weight: lighter;
  text-decoration: none;
}

.tag-parent{
  margin:10px 0;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 2;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #385e40;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #4f8b5c;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font-size:$one - 0.2;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

//to resize the image in base pizza
.regular-base{
  width: 210px !important;
  height: 210px !important;
}