@import "./style/color.scss";
@import "./style/typography.scss";
@import "./style/breakpoint.scss";
* {
  margin: 0;
  padding: 0;
  font-family: $font;
  color: $white-hex;
}

body {
  background-color: $black-hex;
  background-image: radial-gradient($black-rgba-start, $black-rgba-end),
    url("./assets/wood.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

main {
  width: 90%;
  margin: 0 5%;
  padding: 20px 0;
  display:flex;
  flex-direction: column;
  #swipe-up-indicator{
    align-self: center;
    background-color:$black-rgba-end;
    padding:10px 20px;
    border-radius: 50px;
    text-transform: uppercase;
    display:none;
    visibility: hidden;
  }
  header {
    display: flex;
    height: 100%;
    align-items: center;
    button {
      height: 40px;
      width: 80px;
      background-color: $white-rgba-start;
      color: $black-hex;
      text-transform: uppercase;
      font-weight: 500;
      border-radius: 5px;
      border: none;
      transition: all 0.5s ease-in-out;
      letter-spacing: 2px;
      margin-right: 20px;
    }
    button:hover,
    button:focus {
      background-color: $white-rgba-end;
    }
    h2 {
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }
  .vege-btn-c{
    display: grid;
    place-content: center;
    width: 100%;
    padding:30px 0;
    a{
      padding:10px 15px;
      background-color: $red-hex;
      border-radius:5px;
      transition: all .2s ease-in;
      text-decoration: none;
    }
    a:hover{
      opacity:0.8;
    }
  }
}



@media only screen and (max-width: $mobile){
  main{
    #swipe-up-indicator{
      display: block;
      visibility: visible;
    }
  }
}