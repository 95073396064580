@import "./../style/color.scss";
@import "./../style/breakpoint.scss";
.base {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 30% 70%;
  justify-content: center;
  align-items: center;
  .featured {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    z-index: 7;
    .history-btn-container {
      width: 100%;
      display: flex;
      button {
        margin: 0 15%;
        padding: 10px 20px;
        background-color: $white-rgba-end;
        color: $black-hex;
        text-transform: uppercase;
        font-weight: 500;
        border-radius: 5px;
        border: none;
        transition: all 0.5s ease-in-out;
        letter-spacing: 2px;
      }
      button:hover,
      button:focus {
        background-color: $white-rgb;
      }
    }
  }
  .featured-image {
    position: fixed;
    left: -300px;
    top: -300px;
  }
}

@media only screen and (max-width: $tablet) {
  .base {
    display: flex;
    flex-direction: column-reverse;
    .featured {
      height: 30vh;
      justify-content: space-around;

      .history-btn-container {
        justify-content: center;
      }
    }

    .featured-image {
      width: 500px;
      height: 500px;
      left: -250px;
      bottom: -250px;
      top: auto;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .base {
    display: flex;
    flex-direction: column-reverse;
    .featured {
      height: 30vh;
      justify-content: flex-end;
      margin-bottom: 0;
      padding-bottom: 0;
      .history-btn-container {
        justify-content: center;
        margin: 15px 0;
      }
    }

    .featured-image {
      width: 400px;
      height: 400px;
      left: -270px;
      bottom: -270px;
      top: auto;
    }
  }
}
