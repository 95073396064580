@import "./../../style/color.scss";
@import "./../../style/breakpoint.scss";
@import "./../../style/typography.scss";

section.menu-container {
  margin: 50px 0;
  display: flex;
  flex-wrap: wrap;
  flex-flow: wrap;
  width: 100%;
  justify-content: space-around;

  .menu-item {
    width: 350px;
    height: 300px;
    margin: 20px;
    text-align: center;
    overflow: hidden;
    border: 2px solid $white-rgb;
    border-radius: 10px;
    transition: all 0.5s ease-in;
    a {
      text-decoration: none;
    }
    .content-container {
      height: 100%;
      width: 100%;
      display: grid;
      place-content: center;
      a {
        text-decoration: none;
      }
    }
    .content-container:hover,
    .content-container:focus {
      background-image: radial-gradient($red-rgb, $black-rgba-end);
    }
  }
}


@media only screen and (max-width: $mobile) {
    section.menu-container{
        flex-direction: column;
    }
  }
  