@import "./../style/color.scss";
@import "./../style/breakpoint.scss";
@import "./../style/typography.scss";

section.body {
  margin: 20px 0;
  width: auto;
}
section.cover-img {
  margin: 30px 0;
  height: 60vh;
  border-radius: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
p.paragraph {
  padding: 10px 0;
  text-align: justify;
}

section#img-container {
  display: grid;
  place-content: center;
  img {
    width: 800px;
    border-radius: 20px;
  }
}

@media only screen and (max-width: $tablet) {
  section#img-container {
    img {
      width: 400px;
    }
  }
}
.leaflet-container {
  margin-top: 20px;
  width: 100%;
  height: 50vh;
  border-radius: 10px;
}

@media only screen and (max-width: $mobile) {
  p.paragraph {
    font-size: $one - 0.1;
  }
  section#img-container {
    img {
      width: 300px;
    }
  }
}
