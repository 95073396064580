@import "./../../style/typography.scss";
@import "./../../style/breakpoint.scss";
@import "./../../style/color.scss";

footer.colored {
  text-align: center;
  padding: 20px 0;
  width: 100%;
  background-image: radial-gradient($green-rgb, $black-rgba-end);
  section {
    margin: 10px;
    a {
      font-size: $one + 0.5;
      margin: 0 5px;
    }
  }
}
