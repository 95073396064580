@import "./../../style/color.scss";
@import "./../../style/breakpoint.scss";
@import "./../../style/typography.scss";

div.modal {
  position: fixed;
  visibility: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $black-rgba-overlay;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;

  .content-container {
    background-color: $white-hex;
    border-radius: 10px;
    overflow: hidden;
    margin: 5%;
    height: 90%;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    .modal-close-button {
      position: absolute;
      color: $black-rgba-end;
      background: none;
      border: none;
      outline: none;
      top: 10px;
      right: 15px;
      z-index: 55;
    }
    .modal-img-container {
      width: 100%;
      height: 100%;
      background-image: radial-gradient($black-rgba-start, $black-rgba-end),
        url("./../../assets/wood.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 85%;
      }
    }
    .modal-details-container {
      margin: 5%;
      width: 90%;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .info {
        height: 100%;
        h3 {
          color: $black-rgba-end !important;
          text-transform: uppercase;
        }
        p {
          color: $black-rgba-end !important;
          text-align: justify;
          .price-item {
            color: inherit;
            text-transform: capitalize;
            b {
              color: inherit;
            }
          }
        }
      }
      a {
        width: 70%;
        padding: 15px;
        background-color: $red-hex;
        border-radius: 10px;
        border: none;
        align-self: center;
        justify-self: flex-end;
        font-size: $one;
        font-weight: 500;
        letter-spacing: 1px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
      }
      a:hover,
      a:focus {
        opacity: 0.8;
      }
    }
  }

  //For ImageOnlyModal
  .static-container {
    img {
      height: 90vh;
      border-radius: 10px;
    }
    .modal-close-button {
      position: absolute;
      color: $white-hex;
      background: none;
      border: none;
      outline: none;
      top: 20px;
      right: 20px;
      z-index: 55;
    }
  }
}

div.modal.visible {
  visibility: visible;
}

.tag-2{
  padding: 5px 10px;
  border-radius: 50px;
  background-color: rgba(34, 189, 65,0.4);
  color: $black-hex;
  border:  solid 2px $green-rgb;
  font-size: $one - 0.3;
  text-decoration: none;
}

@media only screen and (max-width: $tablet) {
  div.modal {
    .content-container {
      flex-direction: column;
      .modal-close-button {
        color: $black-hex;
      }
      .modal-img-container {
        height: 100%;
        width: 100%;
        overflow: hidden;
        img {
          width: 50%;
        }
      }
    }

    .static-container{
      img{
        width: 90vw;
        height: auto !important;
      }
    }
  }
}
@media only screen and (max-width: $mobile) {
  div.modal {
    .content-container {
      .modal-close-button {
        color: $black-hex;
      }
      flex-direction: column;
      .modal-img-container {
        display: none;
      }
    }
  }
}

