@import "./../../style/typography.scss";
@import "./../../style/breakpoint.scss";
@import "./../../style/color.scss";
footer.transparent {
  div {
    display: flex;
  }
  section {
    margin: 10px;
    a {
      font-size: $one + 0.5;
      margin: 0 5px;
    }
  }
}

@media only screen and (max-width: $mobile) {
  footer {
    height: auto;
    margin: 0;
    background-color: $black-rgba-middle;
    width: 100%;
    div {
      width: 100%;
      justify-content: center;
    }
  }
}
