@import "./../style/color.scss";
@import "./../style/breakpoint.scss";
@import "./../style/typography.scss";



.prep-section{
    display: flex;
    flex-direction: column;
    img{
        margin: 20px 0;
        width: 80%;
        border-radius: 10px;
        align-self: center;
    }
}

.pack-section{
    display: flex;
    flex-direction: column;
   
    .package{
        margin: 20px 0;
        width: 100%;
        height: auto !important;
        align-self: center;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        img{
            width: 40%;
            max-height: 60vh;
            height: auto !important;
            border-radius: 10px;
            margin: 0 15px;
        }

    }
}

.frozen-price{
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    a{
        padding:15px;
        margin-bottom: 10px;
        background-color: $red-hex;
        border-radius: 10px;
        border: none;
        font-size: $one;
        font-weight: 500;
        letter-spacing: 1px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
    }
    a:hover,
    a:focus {
      opacity: 0.8;
    }
}

p.special-tag{
    background-color: $white-rgba-start;
    color:$black-hex;
    b{
        color:$red-hex;
    }
    padding:10px;
    border:white 2px solid;
    margin-top: 10px;
    border-radius: 10px;
}