$font: "Noto Sans JP", sans-serif;

$one: 1rem;

h1 {
  font-size: $one * 3;
}

h2 {
  font-size: ($one * 2) + 0.5;
}

h3 {
  font-size: $one * 2;
}

h4 {
  font-size: $one + 0.5;
}

h5 {
  font-size: $one;
}

h6 {
  font-size: $one - 0.2;
}

p {
  font-size: $one;
  font-weight: 300;
  letter-spacing: 1px;
}

a {
  font-size: $one;
  font-weight: 300;
}

p.light {
  font-size: $one - 0.2;
  font-weight: 100;
  letter-spacing: 1px;
}

.caption {
  font-size: $one;
  font-weight: 100;
  letter-spacing: 2px;
  font-style: italic;
  opacity: 0.5;
}
