@import "./../../style/color.scss";
@import "./../../style/breakpoint.scss";
@import "./../../style/typography.scss";
.menu-grid {
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  height: 100vh;
  grid-template-columns: 50% 50%;
  grid-row: auto auto;
  position: relative;
  z-index: 5;
  overflow: hidden;
  .menu-item {
    width: auto;
    height: auto;
    margin: 20px;
    text-align: center;
    overflow: hidden;
    border: 2px solid $white-rgb;
    border-radius: 10px;
    transition: all 0.5s ease-in;
    a {
      text-decoration: none;
    }
    .content-container {
      height: 100%;
      width: 100%;
      display: grid;
      place-content: center;
      a {
        text-decoration: none;
      }
    }
    .content-container:hover,
    .content-container:focus {
      background-image: radial-gradient($red-rgb, $black-rgba-end);
    }
  }
  .circle-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 2px solid $white-hex;
    position: absolute;
    background-color: $black-hex;

    .logo-container {
      z-index: 27;
    }

    h4 {
      text-align: center;
      position: absolute;
      top: 0;
      width: 100%;
      height: 0;
      font-size: 1.5em;
      padding: 0;
      margin: 0;
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: $tablet) {
  .menu-grid {
    width: 100vw;
    height: 70vh;
    .menu-item {
      .sub {
        margin: 0 30px;
      }
    }
    .circle-container {
      height: 150px;
      width: 150px;
      .logo-container {
        img {
          width: 100px;
          height: 100px;
        }
      }
      h4 {
        font-size: $one;
        letter-spacing: 2px;
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  .menu-grid {
    width: 100vw;
    height: 70vh;
    .menu-item {
      margin: 10px;
      h3 {
        font-size: $one + 0.2;
      }
      .sub {
        font-size: $one - 0.4;
      }
    }
    .circle-container {
      height: 150px;
      width: 150px;
      .logo-container {
        img {
          width: 100px;
          height: 100px;
        }
      }
      h4 {
        font-size: $one;
        letter-spacing: 2px;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .menu-grid {
    .menu-item {
      h3 {
        font-size: $one;
      }
      .sub {
        font-size: $one - 0.4;
        margin: 0 10px;
      }
    }
  }
}
