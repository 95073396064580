$red-rgb: rgb(219, 48, 48);
$red-hex: #db3030;

$green-rgb: rgb(34, 189, 65);
$green-hex: #22bd41;

$black-rgb: rgb(18, 18, 18);
$black-hex: #121212;

$white-rgb: rgb(245, 245, 245);
$white-hex: #f5f5f5;

$black-rgba-start: rgba(18, 18, 18, 0.4);
$black-rgba-middle: rgba(18, 18, 18, 0.65);
$black-rgba-overlay: rgba(18, 18, 18, 0.9);
$black-rgba-end: rgba(18, 18, 18, 1);

$white-rgba-start: rgba(245, 245, 245, 0.5);
$white-rgba-end: rgba(245, 245, 245, 0.8);
