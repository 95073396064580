@import "./../style/color.scss";
@import "./../style/breakpoint.scss";
@import "./../style/typography.scss";

main.error{
    display:flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    a{
        margin:10px;
        background-color: $white-rgba-start;
        color:$black-hex;
        text-decoration: none;
        font-weight: 400;
        text-transform: uppercase;
        padding: 10px 20px;
        border-radius: 10px;
        transition: all 0.3s ease-in;
    }
    a:hover{
        background-color: $white-rgba-end;
    }
    p{
        font-size:$one *1.5;
    }
    h1{
        font-size: $one * 4;
        text-transform: uppercase;
    }
    h1 span:first-of-type{
        color: $green-hex;
    }
    h1 span:last-of-type{
        color: $red-hex;
    }
}